import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    game: {
      client: null,
      room: null,
    },
  },
  mutations: {
    setGame (state, payload) {
      this.state.game = {
        ...this.state.game,
        ...payload,
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
