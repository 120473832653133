<template>
  <div class="container mt-3">

    <div class="mb-3">
      <a href="/" class="btn btn-outline-primary btn-sm">Página inicial</a>
    </div>

    <div v-if="!client" class="form-group">
      <label><b>Qual é o seu nome?</b></label>
      <input v-model="userName" type="text" class="form-control">
      <button class="btn btn-primary mt-1" @click="joinRoom">Continuar</button>
    </div>

    <div v-else class="text-center">

      <p v-if="!room">Conectando...</p>

      <template v-else-if="state">

        <div v-if="state.status == 'waiting'" class="alert alert-info" role="alert">
          Aguardando o jogo começar...
        </div>

        <div v-else-if="game.hasStarted">

          <template v-if="state.status == 'started'">
            <h2>Forme a palavra</h2>

            <span v-for="index in game.word.length" :key="index" class="formedWord">
              <template v-if="game.formedWord[index - 1]">
                {{ game.formedWord[index - 1] }}
              </template>
              <template v-else>_ </template>
            </span>

            <div class="mt-3">
              <button
                v-for="(character, index) in game.wordChars"
                :key="index"
                class="btn btn-warning btn-lg"
                :class="{'ms-2': index > 0}"
                @click="chooseCharacter(character, index)"
                :disabled="game.disabledButtons.includes(index)"
              >
                {{ character }}
              </button>
            </div>
          </template>

          <div v-if="state.status == 'finished'" class="alert alert-success" role="alert">
            <h3>O vencedor é {{ game.players[0].name }}!</h3>
            <router-link :to="{ name: 'Home' }">
              Ir para a página inicial
            </router-link>
          </div>

        </div>

        <hr>

        <h5>Pontuação dos jogadores</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Jogador</th>
              <th>Pontuação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in game.players" :key="player.id">
              <td>{{ player.name }}</td>
              <td>{{ player.score }}</td>
            </tr>
          </tbody>
        </table>

      </template>

    </div>

  </div>
</template>

<script>
import * as Colyseus from 'colyseus.js'

export default {
  name: 'Play',

  components: {
  },

  data () {
    return {
      roomId: null,
      room: null,
      client: null,
      state: null,
      userName: 'Jogador da Web',

      game: {
        hasStarted: false,
        wordIndex: 0,
        word: '',
        wordChars: [],
        formedWord: [],
        disabledButtons: [],
        players: [],
      },
    }
  },

  mounted () {
    const existingClient = this.$store.state.game.client
    const existingRoom = this.$store.state.game.room

    if (existingClient && existingRoom) {
      this.client = existingClient
      this.room = existingRoom
      this.listenChanges()
    } else {
      this.roomId = this.$route.query.room

      if (this.$route.query.userName) {
        this.userName = this.$route.query.userName
      }
  
      if (!this.roomId) {
        location.href = '/'
      }
    }
  },

  methods: {
    connect () {
      // this.client = new Colyseus.Client('ws://localhost:2567')
      this.client = new Colyseus.Client('wss://batalha-de-palavras.herokuapp.com')
    },

    joinRoom () {
      this.connect()
      this.client.joinById(this.roomId, { userName: this.userName }).then(room => {
        console.log('joined successfully', room)
        this.room = room
        this.listenChanges()
      }).catch(e => {
        console.error('join error', e)
      })
    },

    listenChanges () {
      this.room.onStateChange.once(state => {
        console.log("initial room state:", state)
        this.state = state

        this.updateLocalGameState()
      })

      this.room.onStateChange(state => {
        console.log(state)
        this.state = state

        this.updateLocalGameState()
      })

      // room.onMessage('gameFinished', (message) => {
      //   console.log('message received from server')
      //   console.log(message)
      // })
    },

    updateLocalGameState () {
      if (!this.state) {
        return
      }

      if (this.state.status == 'started' && !this.game.hasStarted) {
        this.game.hasStarted = true
        this.prepareAndShowWord()
      }

      this.updatePlayers()

      this.$forceUpdate()
    },

    updatePlayers () {
      const playerMap = {}
      this.state.players.forEach(player => {
        playerMap[player.id] = player
      })
      this.game.players = Object.values(playerMap)
      this.game.players = this.game.players.sort((a, b) => {
        if (a.score > b.score) {
          return -1
        }
        if (b.score > a.score) {
          return 1
        }
        return 0
      })
    },

    nextWord () {
      this.game.wordIndex++
      this.resetFormedWord()
      this.prepareAndShowWord()
    },

    prepareAndShowWord () {
      if (this.state.words[this.game.wordIndex]) {
        this.game.word = this.state.words[this.game.wordIndex]
        this.game.wordChars = this.game.word.split('')
        this.game.wordChars = this.game.wordChars
          .map((a) => ({sort: Math.random(), value: a}))
          .sort((a, b) => a.sort - b.sort)
          .map((a) => a.value)
      }
    },

    chooseCharacter (choosenCharacter, buttonIndex) {
      const charIndex = this.game.formedWord.length
      const correctChar = this.game.word[charIndex]
      const isValidChar = choosenCharacter == correctChar

      if (isValidChar) {
        this.game.formedWord.push(choosenCharacter)
        this.game.disabledButtons.push(buttonIndex)

        const isWordDone = this.game.word.length == this.game.formedWord.length
        if (isWordDone) {
          this.room.send('correctWord')
          this.nextWord()
        }
      } else {
        this.resetFormedWord()
      }
    },

    resetFormedWord () {
      this.game.formedWord = []
      this.game.disabledButtons = []
    },
  },
}
</script>

<style lang="scss" scoped>
.formedWord {
  font-size: 3em;
}
</style>