<template>
  <div class="home mt-3">
    <div class="container">
      <h1>Batalha de Palavras</h1>

      <!-- <button
        class="btn btn-primary"
        @click="toggleCreateRoomModal(true)"
      >
        Criar sala
      </button> -->

      <hr>

      <table v-if="rooms.length > 0" class="table">
        <thead>
          <tr>
            <th>Sala</th>
            <th>Jogadores</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="room in rooms" :key="room.roomId">
            <td>{{ room.metadata.roomName }}</td>
            <td>{{ room.clients }}</td>
            <td>
              <div class="float-end">
                <router-link :to="{ name: 'Play', query: { room: room.roomId }}">
                  Entrar
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-warning" role="alert">
        Nenhuma sala encontrada, crie uma no app!
        Se você ainda não possui o app, baixe <a href="https://play.google.com/store/apps/details?id=com.ACS.BatalhaDePalavras" target="_blank">clicando aqui</a>.
      </div>

    </div>

    <div id="create-room-modal" ref="createRoomModal" class="modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Criar sala</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-3">
              <label>Nome da sala</label>
              <input v-model="newRoom.name" class="form-control" type="text" autocomplete="off">
            </div>
            <div class="form-group">
              <label>Seu nome ou apelido</label>
              <input v-model="newRoom.userName" class="form-control" type="text" autocomplete="off">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="createRoom">Criar sala</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import * as Colyseus from 'colyseus.js'

export default {
  name: 'Home',

  components: {
  },

  data () {
    return {
      client: null,
      rooms: [],

      createRoomModal: null,
      newRoom: {
        name: '',
        userName: '',
      },
    }
  },

  mounted () {
    this.initVars()
    this.connect()

    this.fetchRooms()
    setInterval(() => {
      this.fetchRooms()
    }, 3000)
  },

  methods: {
    initVars () {
      this.createRoomModal = new bootstrap.Modal(this.$refs.createRoomModal)
    },

    connect () {
      // this.client = new Colyseus.Client('ws://localhost:2567')
      this.client = new Colyseus.Client('wss://batalha-de-palavras.herokuapp.com/')
    },

    fetchRooms () {
      this.client.getAvailableRooms().then(rooms => {
        console.log(rooms)
        this.rooms = rooms
        // rooms.forEach((room) => {
        //   console.log(room.roomId)
        //   console.log(room.clients)
        //   console.log(room.maxClients)
        //   console.log(room.metadata)
        // })
      }).catch(e => {
        console.error(e)
      })
    },

    toggleCreateRoomModal(toShow) {
      toShow ? this.createRoomModal.show() : this.createRoomModal.hide()
    },

    createRoom () {
      const roomOptions = { ...this.newRoom  }
      this.client.create('my_room', roomOptions).then(room => {
        console.log(room.sessionId, 'joined', room.name)

        this.$store.commit('setGame', {
          client: this.client,
          room: room,
        })

        this.toggleCreateRoomModal(false)

        this.$router.push({
          name: 'Play',
          query: {
            room: room.id,
            userName: this.newRoom.userName,
          },
        })
      }).catch(e => {
        console.log('JOIN ERROR', e)
      })
    },

    joinRoom (selectedRoom) {
      this.client.joinById(selectedRoom.roomId, {/* options */}).then(room => {
        console.log('joined successfully', room)
      }).catch(e => {
        console.error('join error', e)
      })
    },
  },
}
</script>
